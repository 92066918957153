import { RefObject } from 'react';

import { useIsMobile } from 'components/utilities/MobileDetect';
import { useClickOutside, useToggle } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';

interface Output {
    isMobile: boolean;
    showMobAction: boolean;
    tmbRef: RefObject<HTMLDivElement>;
    toggleMobAction: VoidFunction;
}

const useGameTmb = (): Output => {
    const [showMobAction, toggleMobAction, hideMobAction] = useToggle(false);
    const { until } = useResponsive();
    const isMobile = until('md');

    const { isMobile: isUAMobile } = useIsMobile();
    const isTablet = until('lg') && isUAMobile;

    const tmbRef = useClickOutside(hideMobAction);

    return {
        tmbRef,
        isMobile: isMobile || isTablet,
        showMobAction,
        toggleMobAction,
    };
};

export default useGameTmb;
