import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { Game } from 'types/gql.cms';
import { getTransformedDataTestId } from 'utils';
import { TmbVariant } from '../types';
import {
    imageClasses,
    titleActiveClasses,
    titleClasses,
    wrapperClasses,
} from './styles';
import TmbImage from './TmbImage';
import useGameTmb from './useGameTmb';

import styles from './gameTmb.module.css';

interface Props {
    isAuthorized?: boolean;
    game: Game;
    index?: number;
    variant?: TmbVariant;
    withTitle?: boolean;
    className?: string;
    additionalClasses?: string;
}

const GameTmb: FC<Props> = ({
    isAuthorized,
    className,
    game,
    index = 0,
    variant = TmbVariant.DEFAULT,
    withTitle = true,
    additionalClasses,
}) => {
    const { isMobile, showMobAction, tmbRef, toggleMobAction } = useGameTmb();

    const gameTmbAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'game-tmb',
                isActive: showMobAction && isMobile,
            }),
        [isMobile, showMobAction]
    );

    const gameTmbTitleAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'game-tmb__title',
                isActive: showMobAction && isMobile,
            }),
        [isMobile, showMobAction]
    );

    return (
        <div
            ref={tmbRef}
            className={cn(wrapperClasses[variant], className)}
            onClick={toggleMobAction}
            data-test={gameTmbAttr}
        >
            <TmbImage
                className={cn(imageClasses[variant], {
                    // @ts-ignore NOTE: added different animation timing for each tmb element
                    [styles[`bombshell-animate-${index + 1}`]]:
                        variant === 'bombshell',
                })}
                additionalClasses={additionalClasses}
                isMobile={isMobile}
                isAuthorized={!!isAuthorized}
                showMobAction={showMobAction}
                variant={variant}
                game={game}
            />

            {withTitle && game.title && (
                <div
                    className={cn(titleClasses[variant], {
                        [titleActiveClasses[variant]]:
                            showMobAction && isMobile,
                    })}
                    data-test={gameTmbTitleAttr}
                >
                    {game.title}
                </div>
            )}
        </div>
    );
};

export default memo(GameTmb);
