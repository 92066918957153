import { Vendor } from 'types/gql.cms';

export interface VendorList {
    name: string;
    vendors: Vendor[];
}

export enum GamesListType {
    GAMES = 'games',
    FAVORITE = 'favorite',
    LAST_PLAYED = 'last-played',
    TOURNAMENT = 'tournament',
}

export enum TmbVariant {
    DEFAULT = 'default',
    BOMBSHELL = 'bombshell',
    GAME_PAGE = 'game-tmb',
    FEATURED = 'featured',
    FIRST_BIG_TMB = 'first-big-tmb',
    RECOMMENDED_SLIDER = 'recommended-tmb',
    COLLECTION_CARD = 'collection',
}
