import { useCallback } from 'react';
import { StoreObject } from '@apollo/client';
import to from 'await-to-js';
import { useTranslations } from 'next-intl';

import useAlertMessages from 'components/AlertManager/useAlertMessages';
import gameFragment from 'gql/cms/fragments/gameFragment.cms.gql';
import { useSetGamesFavorite } from 'gql/cms/mutations/games/setGamesFavorite.cms';
import { useCmsApolloClient } from 'services/apollo';
import { logError } from 'services/logger';

type Output = {
    makeToggleFavoriteGame: (gameId: string, isFavorite: boolean) => void;
    loadingSetGamesFavorite: boolean;
};

export const useHandleFavoriteGames = (): Output => {
    const t = useTranslations('AppError');
    const { client } = useCmsApolloClient();
    const [setGamesFavorite, { loading: loadingSetGamesFavorite }] =
        useSetGamesFavorite({ client });
    const { successAlert, errorAlert } = useAlertMessages();

    const makeToggleFavoriteGame = useCallback<
        (gameId: string, isFavorite: boolean) => void
    >(
        (gameId, isFavorite) => {
            (async () => {
                try {
                    const [err, setGamesFavoriteResponse] = await to(
                        setGamesFavorite({
                            variables: {
                                gameId,
                                isFavorite,
                            },
                            update(cache) {
                                const result: StoreObject | null =
                                    cache.readFragment({
                                        id: `Game:${gameId}`,
                                        fragment: gameFragment,
                                        fragmentName: 'Game',
                                        variables: {
                                            isAuthorized: false,
                                            isClient: true,
                                            isNeedDemoPlayLink: false,
                                            isNeedPlayData: false,
                                            isNeedPlayDemoData: false,
                                        },
                                    });

                                if (!result) {
                                    throw new Error(
                                        '[setGamesFavorite] No result'
                                    );
                                }

                                cache.modify({
                                    id: cache.identify(result),
                                    fields: {
                                        favorite() {
                                            return isFavorite;
                                        },
                                    },
                                });
                            },
                        })
                    );

                    if (err) {
                        errorAlert(t('gincms.core.error.something_wrong'));

                        return;
                    }

                    const message =
                        setGamesFavoriteResponse?.data?.setGamesFavorite
                            ?.message;

                    if (!message) {
                        throw new Error('[setGamesFavorite] No message');
                    }

                    successAlert(message);
                } catch (err) {
                    if (err instanceof Error) {
                        logError({
                            message: err.message,
                            err,
                        });
                    }

                    throw new Error(
                        '[setFavoriteGame] error: something went wrong'
                    );
                }
            })();
        },
        [setGamesFavorite, successAlert, errorAlert, t]
    );

    return {
        makeToggleFavoriteGame,
        loadingSetGamesFavorite,
    };
};
