import cn from 'classnames';

import { TmbVariant } from '../../types';

export const newTagClasses = cn(
    'absolute bottom-auto left-0 right-auto top-0 z-1 flex min-h-[25px]',
    'min-w-[25px] items-center justify-center rounded-br-[12px] rounded-tl-[8px]',
    'bg-primary-orange-toxic text-xs text-[#0a0b0d]'
);

export const baseWrapperClasses = cn(
    'relative w-full overflow-hidden pt-[100%] transition-all duration-300'
);

export const wrapperClasses = {
    [TmbVariant.DEFAULT]: cn(baseWrapperClasses, 'rounded-[8px]'),
    [TmbVariant.BOMBSHELL]: cn(baseWrapperClasses, 'rounded-full'),
    [TmbVariant.GAME_PAGE]: cn(baseWrapperClasses, 'rounded-[8px]'),
    [TmbVariant.FEATURED]: cn(baseWrapperClasses, 'rounded-[8px]'),
    [TmbVariant.FIRST_BIG_TMB]: cn(baseWrapperClasses, ' h-full rounded-[8px]'),
    [TmbVariant.RECOMMENDED_SLIDER]: cn(baseWrapperClasses, 'rounded-[8px]'),
    [TmbVariant.COLLECTION_CARD]: cn(baseWrapperClasses, 'rounded-[8px]'),
};

export const imageClasses = {
    [TmbVariant.DEFAULT]: cn(
        'absolute inset-0 z-1 h-full w-full bg-surface-middle'
    ),
    [TmbVariant.BOMBSHELL]: cn(
        'absolute inset-0 z-1 h-full w-full bg-surface-middle'
    ),
    [TmbVariant.GAME_PAGE]: cn(
        'absolute inset-0 z-1 h-full w-full bg-[#14171c]'
    ),
    [TmbVariant.FEATURED]: cn(
        'absolute inset-0 z-1 h-full w-full bg-surface-dark'
    ),
    [TmbVariant.FIRST_BIG_TMB]: cn(
        'absolute inset-0 z-1 h-full w-full bg-surface-middle'
    ),
    [TmbVariant.RECOMMENDED_SLIDER]: cn(
        'absolute inset-0 z-1 h-full w-full bg-surface-middle'
    ),
    [TmbVariant.COLLECTION_CARD]: cn('absolute inset-0 z-1 h-full w-full'),
};

const baseButtonsWrapperClasses = cn(
    'pointer-events-none absolute inset-0 z-[3] flex h-full w-full flex-col px-2 duration-300',
    'items-center justify-center opacity-0 transition-all'
);

export const buttonsWrapperClasses = {
    [TmbVariant.DEFAULT]: cn(baseButtonsWrapperClasses, 'bg-[rgba(0,0,0,.5)]'),
    [TmbVariant.BOMBSHELL]: cn(
        baseButtonsWrapperClasses,
        'bg-[rgba(0,0,0,.5)]'
    ),
    [TmbVariant.GAME_PAGE]: cn(
        baseButtonsWrapperClasses,
        'bg-[rgba(0,0,0,.5)]'
    ),
    [TmbVariant.FEATURED]: baseButtonsWrapperClasses,
    [TmbVariant.FIRST_BIG_TMB]: cn(
        baseButtonsWrapperClasses,
        'bg-[rgba(0,0,0,.5)]'
    ),
    [TmbVariant.RECOMMENDED_SLIDER]: cn(
        baseButtonsWrapperClasses,
        'bg-[rgba(0,0,0,.5)]'
    ),
    [TmbVariant.COLLECTION_CARD]: cn(
        baseButtonsWrapperClasses,
        'group-hover:bg-[rgba(255,179,50,.2)]'
    ),
};

export const playCircleButtonClasses = {
    [TmbVariant.DEFAULT]: '',
    [TmbVariant.BOMBSHELL]: cn(
        'max-h-[35px] min-h-[35px] min-w-[35px] max-w-[35px] fill-primary-white'
    ),
    [TmbVariant.GAME_PAGE]: '',
    [TmbVariant.FEATURED]: cn(
        'max-h-[23px] min-h-[23px] min-w-[23px] max-w-[23px] fill-primary-white'
    ),
    [TmbVariant.FIRST_BIG_TMB]: '',
    [TmbVariant.RECOMMENDED_SLIDER]: '',
    [TmbVariant.COLLECTION_CARD]: cn(
        'max-h-[23px] min-h-[23px] min-w-[23px] max-w-[23px] fill-primary-white'
    ),
};
