import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import { Size, Width } from 'components/Button/types';
import { useHandleFavoriteGames } from 'components/casino/hooks/useHandleFavoriteGames';
import Image from 'components/Image';
import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import { withMessages } from 'hocs';
import { Game } from 'types/gql.cms';
import { getTransformedDataTestId } from 'utils';
import { TmbVariant } from '../../types';
import {
    buttonsWrapperClasses,
    imageClasses,
    newTagClasses,
    playCircleButtonClasses,
    wrapperClasses,
} from './styles';
import { useTmbImage } from './useTmbImage';

import LogoBG from 'assets/svg/components/logobg-gg.svg';

const playNowButton = (
    text: string,
    variant: TmbVariant,
    callback?: VoidFunction
) => (
    <Button
        onClick={callback}
        dataTest="tmb-image__button-play"
        color={Button.Color.Orange}
        size={Size.Small}
        className={cn('text-primary-white')}
        classNameText={cn('typo-sm-2 first-letter:capitalize')}
        width={variant !== TmbVariant.FIRST_BIG_TMB ? Width.Full : Width.Fit}
    >
        {text}
    </Button>
);

const playCircleButton = (
    variant: TmbVariant = TmbVariant.DEFAULT,
    callback?: VoidFunction
) => (
    <button onClick={callback} data-test="tmb-image__button-play">
        <PackIcon
            className={playCircleButtonClasses[variant]}
            pack={IconsPack.SpriteIcons}
            id="play-circle"
        />
    </button>
);

interface Props {
    isAuthorized: boolean;
    game: Game;
    isMobile: boolean;
    showMobAction: boolean;
    variant?: TmbVariant;
    className?: string;
    additionalClasses?: string;
}

const TmbImage: FC<Props> = ({
    isAuthorized,
    className,
    game,
    isMobile,
    showMobAction,
    variant = TmbVariant.DEFAULT,
    additionalClasses,
}) => {
    const t = useTranslations('General');
    const { isOnlyReal, openLogin, openGameRealOnlyModal } = useTmbImage({
        game,
    });
    const { loadingSetGamesFavorite, makeToggleFavoriteGame } =
        useHandleFavoriteGames();
    const isSpecialVariant = useMemo(
        () =>
            [
                TmbVariant.BOMBSHELL,
                TmbVariant.FEATURED,
                TmbVariant.COLLECTION_CARD,
            ].includes(variant),
        [variant]
    );

    const tmbButtonsAttr = useMemo(
        () =>
            getTransformedDataTestId({
                defaultAttribute: 'tmb-image__buttons',
                isActive: isMobile && showMobAction,
            }),
        [isMobile, showMobAction]
    );

    const handleSetFavoriteGame = () => {
        makeToggleFavoriteGame(game.id, !game.favorite);
    };

    const authorizedPlayButton = isAuthorized ? (
        <Button
            href={game.url}
            color={Button.Color.Orange}
            className={cn('text-primary-white')}
            classNameText={cn('typo-sm-2 first-letter:capitalize')}
            dataTest="tmb-image__button-play"
            size={Size.Small}
            width={
                variant !== TmbVariant.FIRST_BIG_TMB ? Width.Full : Width.Fit
            }
        >
            {t('gincms.btn.play_now')}
        </Button>
    ) : (
        playNowButton(t('gincms.btn.play_now'), variant, openLogin)
    );

    const authorizedPlayCircleButton = isAuthorized ? (
        <SmartLink href={game.url} dataTest="tmb-image__button-play">
            <PackIcon
                className={playCircleButtonClasses[variant]}
                pack={IconsPack.SpriteIcons}
                id="play-circle"
            />
        </SmartLink>
    ) : (
        playCircleButton(variant, openLogin)
    );

    return (
        <div
            className={cn(wrapperClasses[variant], className)}
            data-test="tmb-image"
        >
            {game.imageId ? (
                <Image
                    className={imageClasses[variant]}
                    src={`/file/${game.imageId}`}
                    alt={game.title}
                    fill={true}
                    style={{ objectFit: 'cover' }}
                    data-test="tmb-image__img"
                    unoptimized={true}
                />
            ) : (
                <div
                    className={imageClasses[variant]}
                    data-test="tmb-image__img"
                >
                    {!isSpecialVariant && (
                        <LogoBG
                            className={cn(
                                'absolute h-full min-h-full w-full min-w-full fill-[#1a1a1a]',
                                'p-[20px] transition-all duration-300'
                            )}
                        />
                    )}
                </div>
            )}
            {game.new && !isSpecialVariant && (
                <div className={newTagClasses} data-test="tmb-image__tag">
                    {t('gincms.label.new')}
                </div>
            )}
            {variant !== TmbVariant.RECOMMENDED_SLIDER && (
                <div
                    className={cn(buttonsWrapperClasses[variant], {
                        'pointer-events-auto opacity-100':
                            isMobile && showMobAction,
                        'sm:group-hover:pointer-events-auto sm:group-hover:opacity-100':
                            !isMobile,
                    })}
                    data-test={tmbButtonsAttr}
                >
                    {isAuthorized && !isSpecialVariant && (
                        <Button
                            type="button"
                            data-test="tmb-image__favorite"
                            className="absolute right-0 top-1 z-2"
                            isDisabled={loadingSetGamesFavorite}
                            width={Width.Fit}
                            size={Size.ExtraSmall}
                            onClick={handleSetFavoriteGame}
                        >
                            <PackIcon
                                className={
                                    loadingSetGamesFavorite
                                        ? 'fill-grey-100'
                                        : 'fill-primary-white'
                                }
                                size={SvgIconSize.WH_4}
                                id={game.favorite ? 'star-fill' : 'star'}
                                pack={IconsPack.SpriteIcons}
                            />
                        </Button>
                    )}

                    {additionalClasses && <div className={additionalClasses} />}

                    {!isSpecialVariant &&
                        (isOnlyReal
                            ? playNowButton(
                                  t('gincms.btn.play_now'),
                                  variant,
                                  openGameRealOnlyModal
                              )
                            : authorizedPlayButton)}

                    {isSpecialVariant &&
                        (isOnlyReal
                            ? playCircleButton(variant, openGameRealOnlyModal)
                            : authorizedPlayCircleButton)}

                    {!isSpecialVariant && game.supportDemo && (
                        <Button
                            className={cn('text-primary-white typo-sm-2')}
                            href={game.demoUrl}
                            dataTest="tmb-image__demo"
                            size={Size.Small}
                            width={Width.Full}
                        >
                            {t('gincms.btn.demo')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default withMessages(memo(TmbImage), ['AppError']);
