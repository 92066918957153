import { useCallback } from 'react';

import { ModalsHash } from 'app-constants';
import { useGameOnlyReal } from 'components/casino/hooks';
import useModal from 'components/modals/useModal';
import { Game } from 'types/gql.cms';

type Input = {
    game: Game;
};
type Output = {
    isOnlyReal: boolean;
    openLogin: VoidFunction;
    openGameRealOnlyModal: VoidFunction;
};

export const useTmbImage = ({ game }: Input): Output => {
    const { openModal } = useModal();
    const { isOnlyReal, openGameRealOnlyModal } = useGameOnlyReal(game);

    const openLogin = useCallback(() => {
        openModal({
            hash: ModalsHash.Registration,
            redirect: game.url,
        });
    }, [openModal, game]);

    return {
        isOnlyReal,
        openGameRealOnlyModal,
        openLogin,
    };
};
