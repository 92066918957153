import { useCallback } from 'react';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { usePlayerBalances } from 'hooks';

interface BalancesInput {
    realBalance: number;
    bettingBonuses: number;
    casinoBonuses: number;
    instaBonuses: number;
    liveCasinoBonuses: number;
}

interface GameInput {
    url: string;
    balanceGroupName?: string;
}

interface Output {
    openGameRealOnlyModal: VoidFunction;
    isOnlyReal: boolean;
}

const useGameOnlyReal = (game: GameInput): Output => {
    const balances = usePlayerBalances();
    const { openModal } = useModal();
    const openGameRealOnlyModal = useCallback(() => {
        openModal({ hash: ModalsHash.PlayOnReal, data: { gameUrl: game.url } });
    }, [openModal, game]);

    const isOnlyReal = balances
        ? Boolean(
              !balances?.realBalance &&
                  !gameBonusBalance(game.balanceGroupName || '', balances) &&
                  totalBonusBalance(balances)
          )
        : false;

    return {
        openGameRealOnlyModal,
        isOnlyReal,
    };
};

function gameBonusBalance(group: string, balances: BalancesInput): number {
    switch (group) {
        case 'casino':
            return balances?.casinoBonuses || 0;
        case 'betting':
            return balances?.bettingBonuses || 0;
        case 'instant-games':
            return balances?.instaBonuses || 0;
        case 'live-casino':
            return balances?.liveCasinoBonuses || 0;
        default:
            return 0;
    }
}

function totalBonusBalance(balances: BalancesInput): number {
    return (
        Number(balances?.bettingBonuses) +
        Number(balances?.casinoBonuses) +
        Number(balances?.instaBonuses) +
        Number(balances?.liveCasinoBonuses)
    );
}

export default useGameOnlyReal;
