import * as GQL from 'types/gql.cms';

import * as Operations from './setGamesFavorite.cms.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/cms';
const defaultOptions = {} as const;

export type SetGamesFavoriteMutationFn = Apollo.MutationFunction<
    GQL.SetGamesFavorite,
    GQL.SetGamesFavoriteVariables
>;

/**
 * __useSetGamesFavorite__
 *
 * To run a mutation, you first call `useSetGamesFavorite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGamesFavorite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGamesFavorite, { data, loading, error }] = useSetGamesFavorite({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      isFavorite: // value for 'isFavorite'
 *   },
 * });
 */
export function useSetGamesFavorite(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.SetGamesFavorite,
        GQL.SetGamesFavoriteVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.SetGamesFavorite,
        GQL.SetGamesFavoriteVariables
    >(Operations.SetGamesFavorite, options);
}
export type SetGamesFavoriteHookResult = ReturnType<typeof useSetGamesFavorite>;
export type SetGamesFavoriteMutationResult =
    Apollo.MutationResult<GQL.SetGamesFavorite>;
export type SetGamesFavoriteMutationOptions = Apollo.BaseMutationOptions<
    GQL.SetGamesFavorite,
    GQL.SetGamesFavoriteVariables
>;
