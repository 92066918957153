import cn from 'classnames';

import { TmbVariant } from '../types';

import styles from './gameTmb.module.css';

export const wrapperClasses = {
    [TmbVariant.DEFAULT]: cn(
        'group relative mb-[20px] flex w-full flex-col rounded-[8px]',
        'border-none hover:scale-105'
    ),
    [TmbVariant.BOMBSHELL]: cn(
        'group relative mb-[20px] mt-[35px] inline-block px-3',
        'hover:scale-105',
        styles['bombshell-games']
    ),
    [TmbVariant.GAME_PAGE]: cn(
        'group relative mb-[20px] flex w-full flex-col rounded-[8px]',
        'border-none'
    ),
    [TmbVariant.FEATURED]: cn('relative'),
    [TmbVariant.FIRST_BIG_TMB]: cn(
        'group relative mb-4 flex w-full flex-col rounded-[8px]',
        'border-none first:col-[span_2_/auto] first:row-[span_2_/auto] hover:scale-105'
    ),
    [TmbVariant.RECOMMENDED_SLIDER]: cn('relative rounded-[8px]'),
    [TmbVariant.COLLECTION_CARD]: cn(
        'group relative rounded-[8px] bg-surface-dark transition-[transform] duration-300 hover:scale-105'
    ),
};

export const wrapperActiveClasses = {
    [TmbVariant.DEFAULT]: cn('scale-105'),
    [TmbVariant.BOMBSHELL]: cn('scale-105'),
    [TmbVariant.GAME_PAGE]: cn('scale-105'),
    [TmbVariant.FEATURED]: cn('scale-105'),
    [TmbVariant.FIRST_BIG_TMB]: cn('scale-105'),
    [TmbVariant.RECOMMENDED_SLIDER]: '',
    [TmbVariant.COLLECTION_CARD]: '',
};

export const imageClasses = {
    [TmbVariant.DEFAULT]: '',
    [TmbVariant.BOMBSHELL]: cn(styles['bombshell-animate']),
    [TmbVariant.GAME_PAGE]: '',
    [TmbVariant.FEATURED]: cn(
        'h-[97px] min-h-[97px] w-[97px] min-w-[97px]',
        'md:h-[88px] md:min-h-[88px] md:w-[88px] md:min-w-[88px]'
    ),
    [TmbVariant.FIRST_BIG_TMB]: '',
    [TmbVariant.RECOMMENDED_SLIDER]: '',
    [TmbVariant.COLLECTION_CARD]: '',
};

export const titleClasses = {
    [TmbVariant.DEFAULT]: cn(
        'pt-[7px] sm:group-hover:scale-95 sm:group-hover:text-primary-white',
        'text-sm leading-[14px] text-grey-500'
    ),
    [TmbVariant.BOMBSHELL]: cn(
        'pt-[7px] sm:group-hover:scale-95 sm:group-hover:text-primary-white',
        'truncate text-base font-medium',
        'leading-4 text-primary-white'
    ),
    [TmbVariant.GAME_PAGE]: cn(
        'pt-[7px] text-sm leading-[14px] text-grey-500',
        ''
    ),
    [TmbVariant.FEATURED]: '',
    [TmbVariant.FIRST_BIG_TMB]: cn(
        'mt-1 min-h-[20px] text-ellipsis text-sm leading-[20px] text-grey-500',
        'line-clamp-1 sm:group-hover:scale-95 sm:group-hover:text-primary-white'
    ),
    [TmbVariant.RECOMMENDED_SLIDER]: cn(
        'pt-[7px] text-sm leading-[14px] text-grey-500'
    ),
    [TmbVariant.COLLECTION_CARD]: '',
};

export const titleActiveClasses = {
    [TmbVariant.DEFAULT]: cn('scale-95 text-primary-white'),
    [TmbVariant.BOMBSHELL]: cn('scale-95 text-primary-white'),
    [TmbVariant.GAME_PAGE]: cn('scale-95 text-primary-white'),
    [TmbVariant.FEATURED]: cn('scale-95 text-primary-white'),
    [TmbVariant.FIRST_BIG_TMB]: cn('scale-95 text-primary-white'),
    [TmbVariant.RECOMMENDED_SLIDER]: '',
    [TmbVariant.COLLECTION_CARD]: '',
};
